<template>
  <div
    class="modal fade show"
    @click="closePopup"
    style="display: block !important"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content text-center">
        <button
          class="btn-close modal-close"
          type="button"
          data-bs-dismiss="modal"
          @click="closePopup"
          aria-label="Close"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.863757 0.861341C1.19357 0.531527 1.7283 0.531527 2.05812 0.861341L13.4046 12.2078C13.7344 12.5376 13.7344 13.0723 13.4046 13.4021C13.0747 13.732 12.54 13.7319 12.2102 13.4021L0.863757 2.0557C0.533944 1.72589 0.533943 1.19115 0.863757 0.861341Z"
              fill="#625B7A"
            />
            <path
              d="M0.862806 13.4022C0.532992 13.0723 0.532992 12.5376 0.862806 12.2078L12.2092 0.861356C12.5391 0.531542 13.0738 0.531541 13.4036 0.861355C13.7334 1.19117 13.7334 1.7259 13.4036 2.05572L2.05717 13.4021C1.72735 13.732 1.19262 13.732 0.862806 13.4022Z"
              fill="#625B7A"
            />
          </svg>
        </button>
        <div class="section-content-icon">
          <img src="@/assets/images/modal-icon.svg" alt="coinfuze" />
        </div>
        <div class="modal-content-title text-white">
          {{ $t("pop-up-title") }}
        </div>
        <button
          class="button bt-gr modal-content-buttom"
          data-bs-dismiss="modal"
          @click="closePopup"
          aria-label="Close"
        >
          <span>Ok</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupItem",
  data() {
    return {};
  },
  methods: {
    closePopup() {
      this.$emit("close-popup");
    }
  }
};
</script>
<style scoped lang="scss">
.dark-theme {
  .modal {
    background: #0a0a0b;
  }
}
.modal {
  background: white;
  padding: 2rem;
  border-radius: 1.5rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.modal-content-title {
  font-size: 1.5rem;
  text-align: center;
}
.modal-close {
  position: absolute;
  padding: 0;
  cursor: pointer;
  border: none;
  width: 1rem;
  height: 1rem;
  right: 1rem;
  top: 1rem;
  background-color: transparent;
  z-index: 3;
}

.button {
  border: none;
  width: 50% !important;
  max-width: 300px;
  line-height: inherit;
  text-transform: none;
  background: linear-gradient(165.91deg, #503578, #3baaac 100.95%);
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-weight: 500;
  font-size: 0.875rem;
  min-height: 2.938rem;
  padding: 0.3rem 1.75rem;
  box-sizing: border-box;
  text-decoration: none;
  color: white;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  span {
    z-index: 2;
  }
}
.button:hover {
  outline: 0;
  outline-offset: 0;
}
.button:before {
  content: "";
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border-radius: 0.438rem;
  background: none;
  transition: opacity 0.2s ease-in-out;
  box-sizing: border-box;
}
</style>
