export default {
  btn_in: "Вход",
  "footer-item-1": "Главная",
  "footer-item-2": "О нас",
  "footer-item-3": "Первые шаги",
  "footer-item-4": "Инвестирование",
  "footer-item-5": "Реферальная программа",
  "footer-item-6": "Статистика",
  "footer-item-7": "Статус системы",

  "footer-item-8": "Разделы",
  "footer-item-8-1": "Ресурсы",
  "footer-item-9": "Компания",
  "footer-item-9-1": "Политика AML",
  "footer-item-9-2": "Условия использования вебсайта",
  "footer-item-9-3": "Политика конфиденциальности",

  "footer-item-10": "Связаться с нами",
  "footer-item-11": "FAQ",
  "footer-item-12": "Документация",

  "footer-form-1": "Имя",
  "footer-form-2": "Телефон",
  "footer-form-3": "Электронная почта",
  "footer-form-4": "Ваше сообщение",
  "footer-btn": "Отправить",
  "pop-up-title": "Ваше сообщение успешно отправлено"
};
