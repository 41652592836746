import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/locales/index";
import "./assets/icomoon/style.css";
import vClickOutside from "v-click-outside";

Vue.config.productionTip = true;
Vue.use(vClickOutside);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
