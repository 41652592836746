<template>
  <div class="footer sh-line">
    <div>
      <a class="logo" href="https://coinfuze.com/" target="_blank">
        <img
          v-if="darkTheme"
          class="header__logo header__logo--desktop"
          src="@/assets/images/logo/logo.svg"
          alt="coinfuze"
        />
        <img
          v-else
          class="header__logo header__logo--desktop"
          src="@/assets/images/logo/coinfuze_black.svg"
          alt="coinfuze"
        />
      </a>
    </div>
    <div class="groups-wrapper">
      <div
        v-for="(group, idx) in itemsGroups"
        :key="idx + 'group'"
        class="group"
      >
        <div
          v-for="(block, idx) in group.blocks"
          :key="idx + 'block'"
          class="block"
        >
          <p class="block-title" v-if="block.title">{{ block.title }}</p>
          <a
            v-for="(link, idx) in block.links"
            :key="idx + 'link' + link.label"
            class="block-link"
            :href="link.link"
            target="_blank"
          >
            {{ link.label }}
          </a>
        </div>
        <div class="socials-wrapper" v-if="group.socials">
          <ul class="socials-list">
            <li>
              <a href="https://discord.gg/B6MCMDR9qP" target="_blank"
                ><svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.6644 4.8862C16.4453 4.31787 15.1253 3.90537 13.7503 3.66703C13.7382 3.66665 13.7262 3.66891 13.7151 3.67366C13.704 3.67841 13.6941 3.68554 13.6861 3.69453C13.5211 3.99703 13.3286 4.3912 13.2003 4.6937C11.7419 4.4737 10.2587 4.4737 8.80027 4.6937C8.67194 4.38203 8.47944 3.99703 8.30527 3.69453C8.2961 3.6762 8.2686 3.66703 8.2411 3.66703C6.8661 3.90537 5.55527 4.31787 4.32694 4.8862C4.31777 4.8862 4.3086 4.89537 4.29944 4.90453C1.8061 8.63537 1.1186 12.2654 1.45777 15.8587C1.45777 15.877 1.46694 15.8954 1.48527 15.9045C3.13527 17.1145 4.7211 17.8479 6.2886 18.3337C6.3161 18.3429 6.3436 18.3337 6.35277 18.3154C6.71944 17.8112 7.04944 17.2795 7.3336 16.7204C7.35194 16.6837 7.3336 16.647 7.29694 16.6379C6.77444 16.4362 6.27944 16.1979 5.7936 15.9229C5.75694 15.9045 5.75694 15.8495 5.78444 15.822C5.88527 15.7487 5.9861 15.6662 6.08694 15.5929C6.10527 15.5745 6.13277 15.5745 6.1511 15.5837C9.30444 17.0229 12.7053 17.0229 15.8219 15.5837C15.8403 15.5745 15.8678 15.5745 15.8861 15.5929C15.9869 15.6754 16.0878 15.7487 16.1886 15.8312C16.2253 15.8587 16.2253 15.9137 16.1794 15.932C15.7028 16.2162 15.1986 16.4454 14.6761 16.647C14.6394 16.6562 14.6303 16.702 14.6394 16.7295C14.9328 17.2887 15.2628 17.8204 15.6203 18.3245C15.6478 18.3337 15.6753 18.3429 15.7028 18.3337C17.2794 17.8479 18.8653 17.1145 20.5153 15.9045C20.5336 15.8954 20.5428 15.877 20.5428 15.8587C20.9461 11.7062 19.8736 8.1037 17.7011 4.90453C17.6919 4.89537 17.6828 4.8862 17.6644 4.8862ZM7.81027 13.6679C6.8661 13.6679 6.07777 12.797 6.07777 11.7245C6.07777 10.652 6.84777 9.7812 7.81027 9.7812C8.78194 9.7812 9.55194 10.6612 9.54277 11.7245C9.54277 12.797 8.77277 13.6679 7.81027 13.6679ZM14.1994 13.6679C13.2553 13.6679 12.4669 12.797 12.4669 11.7245C12.4669 10.652 13.2369 9.7812 14.1994 9.7812C15.1711 9.7812 15.9411 10.6612 15.9319 11.7245C15.9319 12.797 15.1711 13.6679 14.1994 13.6679Z"
                    fill="white"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/coinfuze_ru/" target="_blank">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.75 1H6.25C3.3505 1 1 3.3505 1 6.25V15.75C1 18.6495 3.3505 21 6.25 21H15.75C18.6495 21 21 18.6495 21 15.75V6.25C21 3.3505 18.6495 1 15.75 1ZM19 15.75C19 17.483 17.483 19 15.75 19H6.25C4.517 19 3 17.483 3 15.75V6.25C3 4.517 4.517 3 6.25 3H15.75C17.483 3 19 4.517 19 6.25V15.75Z"
                    fill="white"
                  />
                  <path
                    d="M11 7C8.23858 7 6 9.23858 6 12C6 14.7614 8.23858 17 11 17C13.7614 17 16 14.7614 16 12C16 9.23858 13.7614 7 11 7ZM11 15C9.34315 15 8 13.6569 8 12C8 10.3431 9.34315 9 11 9C12.6569 9 14 10.3431 14 12C14 13.6569 12.6569 15 11 15Z"
                    fill="white"
                  />
                  <path
                    d="M16.5 6C16.0858 6 15.75 6.33579 15.75 6.75C15.75 7.16421 16.0858 7.5 16.5 7.5C16.9142 7.5 17.25 7.16421 17.25 6.75C17.25 6.33579 16.9142 6 16.5 6Z"
                    fill="white"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://t.me/coinfuze" target="_blank"
                ><svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3045_79)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.129 4.0608C18.3555 3.96546 18.6035 3.93258 18.847 3.96558C19.0906 3.99857 19.3208 4.09625 19.5138 4.24843C19.7068 4.40061 19.8555 4.60174 19.9443 4.83088C20.0332 5.06002 20.0591 5.30879 20.0192 5.5513L17.9402 18.1619C17.7385 19.3783 16.4038 20.0759 15.2883 19.47C14.3551 18.9631 12.9691 18.1821 11.7224 17.3671C11.0991 16.9592 9.18967 15.653 9.42434 14.7235C9.62601 13.9287 12.8343 10.9422 14.6677 9.16664C15.3873 8.46905 15.0591 8.06663 14.2093 8.7083C12.0992 10.3015 8.71117 12.7242 7.59101 13.4062C6.60284 14.0076 6.08767 14.1102 5.47167 14.0076C4.34784 13.8206 3.30559 13.5309 2.45492 13.178C1.30542 12.7013 1.36134 11.121 2.45401 10.6608L18.129 4.0608Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3045_79">
                      <rect width="22" height="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/Coin_fuze" target="_blank"
                ><svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5692 9.73657L18.7114 2.75H17.2556L11.9238 8.81611L7.66324 2.75H2.75L9.19181 11.924L2.75 19.25H4.20578L9.8372 12.8433L14.3368 19.25H19.25L12.5692 9.73657ZM10.576 12.0041L9.92333 11.0907L4.72985 3.8225H6.96568L11.1559 9.68825L11.8086 10.6016L17.2568 18.227H15.021L10.576 12.0041Z"
                    fill="white"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/coinfuze" target="_blank"
                ><svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1666 11.0002C20.1666 5.94016 16.0599 1.8335 10.9999 1.8335C5.93992 1.8335 1.83325 5.94016 1.83325 11.0002C1.83325 15.4368 4.98659 19.131 9.16659 19.9835V13.7502H7.33325V11.0002H9.16659V8.7085C9.16659 6.93933 10.6058 5.50016 12.3749 5.50016H14.6666V8.25016H12.8333C12.3291 8.25016 11.9166 8.66266 11.9166 9.16683V11.0002H14.6666V13.7502H11.9166V20.121C16.5458 19.6627 20.1666 15.7577 20.1666 11.0002Z"
                    fill="white"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer-item">
      <div class="block-title">{{ $t("footer-item-10") }}</div>
      <form
        class="footer-contact"
        action="formSend"
        method="POST"
        @submit.prevent="sendRequest"
        @keydown="formSend.onKeydown($event)"
      >
        <div class="row">
          <div class="col-12">
            <div class="input-group">
              <input
                class="input-control"
                v-model="formSend.name"
                autocomplete="off"
                type="text"
                :placeholder="$t('footer-form-1')"
                required
              />
            </div>
          </div>
          <div class="input-wrapper">
            <div class="input-group">
              <input
                class="input-control"
                type="tel"
                v-model="formSend.phone"
                autocomplete="off"
                :placeholder="$t('footer-form-2')"
                required
              />
            </div>
            <div class="input-group">
              <input
                class="input-control"
                type="email"
                v-model="formSend.email"
                autocomplete="off"
                :placeholder="$t('footer-form-3')"
                required
              />
            </div>
          </div>

          <div class="col-12">
            <div class="input-group">
              <textarea
                class="input-control"
                required
                v-model="formSend.message"
                autocomplete="4off"
                :placeholder="$t('footer-form-4')"
              >
              </textarea>
            </div>
          </div>
          <div class="col-4">
            <button
              class="bt bt-gr bt-gr-ins w-100"
              type="submit"
              data-bs-toggle="modal"
              data-bs-target="#messageModal"
            >
              <span>{{ $t("footer-btn") }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="footer-bottom sh-line">
      <div class="footer-copy">
        &copy; 2018 - 2024 Coin Fuze LLC. All rights reserved.
      </div>
      <div class="footer-meta">
        Coin Fuze LTD (also known as "<a
          href="https://coinfuze.com/"
          target="_blank"
          >CoinFuze.com</a
        >") is a Private Limited Company registered with The Registrar of
        Companies for England and Wales under
        <a href="https://coinfuze.com/" target="_blank">number 14519008</a>. Our
        registered address is 63-66 HATTON GARDEN, LONDON, ENGLAND, EC1N 8LE.
        <a href="https://coinfuze.com/" target="_blank">CoinFuze.com</a> is not
        licensed for financial services. Please note that profits from past
        periods do not guarantee sustained returns or the absence of losses in
        future periods.
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Form } from "vform";
export default {
  name: "AppFooter",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      formSend: new Form({
        name: "",
        phone: "",
        email: "",
        message: ""
      }),
      headers: {
        Accept: "*/*",
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "GET,POST"
      }
    };
  },
  computed: {
    ...mapGetters({
      currentLocale: "app/getLocale",
      locales: "app/getLocalesList",
      theme: "app/getTheme"
    }),
    darkTheme() {
      return this.theme === "dark";
    },
    itemsGroups() {
      return [
        {
          blocks: [
            {
              title: this.$t("footer-item-8"),
              links: [
                {
                  label: this.$t("footer-item-1"),
                  link: `https://coinfuze.com/${this.currentLocale}/`
                },
                {
                  label: this.$t("footer-item-3"),
                  link: `https://coinfuze.com/${this.currentLocale}/first-steps`
                },
                {
                  label: this.$t("footer-item-4"),
                  link: `https://coinfuze.com/${this.currentLocale}/investing`
                },
                {
                  label: this.$t("footer-item-5"),
                  link: `https://coinfuze.com/${this.currentLocale}/affiliate`
                }
              ]
            },
            {
              title: this.$t("footer-item-8-1"),
              links: [
                {
                  label: this.$t("footer-item-11"),
                  link: `https://faq.coinfuze.com/${this.currentLocale}/general-issues/how-do-i-get-started-with`
                },
                {
                  label: this.$t("footer-item-6"),
                  link: `https://coinfuze.com/${this.currentLocale}/statistic`
                }
              ]
            }
          ]
        },
        {
          blocks: [
            {
              title: this.$t("footer-item-9"),
              links: [
                {
                  label: this.$t("footer-item-2"),
                  link: `https://coinfuze.com/${this.currentLocale}/about-us`
                },
                {
                  label: this.$t("footer-item-7"),
                  link: `https://coinfuze.com/${this.currentLocale}/status`
                }
              ]
            },
            {
              title: false,
              links: [
                {
                  label: this.$t("footer-item-9-1"),
                  link: `https://coinfuze.com/docs/AML_${this.currentLocale}.pdf`
                },
                {
                  label: this.$t("footer-item-9-2"),
                  link: `https://coinfuze.com/docs/General_Terms_${this.currentLocale}.pdf`
                },
                {
                  label: this.$t("footer-item-9-3"),
                  link: `https://coinfuze.com/docs/Privacy_Policy_${this.currentLocale}.pdf`
                }
              ]
            }
          ],
          socials: true
        }
      ];
    }
  },
  watch: {},
  mounted() {},
  methods: {
    async sendRequest() {
      let headers = this.headers;
      let param = "footerform";
      this.formSend.advid = "footerform";
      try {
        await this.formSend.post(
          `https://custxperts.com/api/registration/?advid=${param}`,
          { headers }
        );
        this.$emit("open-popup");
        this.notificationShow = true;
        this.formSend.name = "";
        this.formSend.phone = "";
        this.formSend.email = "";
        this.formSend.message = "";
      } catch (error) {
        this.errorSuccessful = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.footer {
  padding-top: 1.5rem;
  position: relative;
  img {
    width: 110px;
    height: auto;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 20px;
  width: 100%;
}
.groups-wrapper {
  display: flex;
  width: 100%;
}
.group {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
}
.block {
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
    color: #1c1e21;
    transition: color 0.2s ease-in-out;
    margin-bottom: 0.3rem;
    &:hover {
      color: #37bbc6 !important;
    }
  }
}
.block-title {
  margin-bottom: 1.1rem;
  font-size: 1rem;
  color: #9590a9;
}
.socials-list {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  li {
    list-style-type: none;

    a {
      path {
        fill: #3a3e44;
        transition: fill ease-in-out 0.2s;
      }
      &:hover {
        path {
          fill: #37bbc6;
        }
      }
    }
  }

  ul {
    margin-left: 0;
    padding-left: 0;
  }
}
.sh-line:before {
  content: "";
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 1px;
  background-image: linear-gradient(
    90deg,
    transparent,
    hsla(0, 0%, 100%, 0.1),
    transparent
  );
}
.footer-item {
  margin-top: 1rem;
  width: 100%;
}
.footer-bottom {
  position: relative;
  color: #9590a9;
  padding: 1.5rem 0;
  box-sizing: border-box;
  font-size: 0.75rem;
}
.footer-copy {
  font-size: 0.875rem;
  margin-bottom: 1rem;
}
.footer-meta {
  a {
    text-decoration: none;
    color: #9590a9;
  }
}
.input {
  &-group {
    margin-bottom: 1rem;
  }
  &-control {
    font-family: inherit;
    font-size: 0.875rem;
    color: white;
    background-color: #eaecf2;

    max-width: 100%;
    min-height: 2.625rem;
    width: 100%;
    border: none;
    border-radius: 0.5rem;
    padding: 0 16px;
    box-sizing: border-box;
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      color: #9590a9;
    }
    &-custom {
      min-height: 2.938rem;
      border-radius: 0.5rem;
      border: 1px solid #171d23;
      background: #10161c;
    }
  }
}
textarea {
  &.input-control {
    resize: none;
    padding-top: 1rem;
    min-height: 5rem;
  }
}
button {
  border: none;
  width: 50% !important;
  max-width: 300px;
  line-height: inherit;
  text-transform: none;
  background: linear-gradient(165.91deg, #503578, #3baaac 100.95%);
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-weight: 500;
  font-size: 0.875rem;
  min-height: 2.938rem;
  padding: 0.3rem 1.75rem;
  box-sizing: border-box;
  text-decoration: none;
  color: white;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  span {
    z-index: 2;
  }
}
button:hover {
  outline: 0;
  outline-offset: 0;
}
button:before {
  content: "";
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border-radius: 0.438rem;
  background: none;
  transition: opacity 0.2s ease-in-out;
  box-sizing: border-box;
}
.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0;

  .input-group {
    width: 100%;
  }
  @media (min-width: 575px) {
    flex-direction: row;
    gap: 1rem;
    .input-group {
      width: 50%;
    }
  }
}
</style>
