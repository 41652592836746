export default {
  btn_in: "ورود",

  "footer-item-1": "خانه",
  "footer-item-2": "درباره ما",
  "footer-item-3": "قدم‌های اول",
  "footer-item-4": "سرمایه‌گذاری",
  "footer-item-5": "برنامه معرفی",
  "footer-item-6": "آمار",
  "footer-item-7": "وضعیت سیستم",
  "footer-item-8": "بخش‌ها",
  "footer-item-8-1": "منابع",
  "footer-item-9": "شرکت",
  "footer-item-9-1": "سیاست AML",
  "footer-item-9-2": "شرایط استفاده از وب‌سایت",
  "footer-item-9-3": "سیاست حفظ حریم خصوصی",
  "footer-item-10": "تماس با ما",
  "footer-item-11": "سوالات متداول",
  "footer-item-12": "اسناد",

  "footer-form-1": "نام",
  "footer-form-2": "تلفن",
  "footer-form-3": "ایمیل",
  "footer-form-4": "پیام شما",
  "footer-btn": "ارسال",
  "pop-up-title": "پیام شما با موفقیت ارسال شده است"
};
