import AppServices from "../../services/app.services";

let state = {
  locale: "",
  localesList: ["ru", "en", "it", "es", "de", "nl", "tr", "fa"],
  docsData: [],
  theme: "dark",
  intendedRoute: "",
  isLoadingData: true
};

const mutations = {
  SET_LOCALE(state, locale) {
    const topLevelDomain = ".coinfuze.com";
    document.cookie = `locale=${encodeURIComponent(
      locale
    )};domain=${topLevelDomain};path=/`;
    state.locale = locale;
  },
  SET_DOCS_DATA(state, data) {
    state.docsData = data;
  },
  SET_THEME(state, theme) {
    state.theme = theme;
  },
  SET_INTENDED_ROUTE(state, route) {
    state.intendedRoute = route;
  },
  SET_LOADING(state, data) {
    state.isLoadingData = data;
  }
};

const actions = {
  setLocale({ commit }, locale) {
    commit("SET_LOCALE", locale);
  },
  getDocsData({ commit }) {
    return new Promise((resolve, reject) => {
      AppServices.get("getDocsData")
        .then((response) => {
          commit("SET_DOCS_DATA", response.data.results);
          return resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_LOADING", false);
        });
    });
  }
};

const getters = {
  getLocale: (state) => state.locale,
  getLocalesList: (state) => state.localesList,
  getTheme: (state) => state.theme,
  getDocsData: (state) => state.docsData,
  getDefaultSlug: (state) => state.docsData[0]?.slug,
  getIntendedRoute: (state) => state.intendedRoute,
  isLoadingData: (state) => state.isLoadingData
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
