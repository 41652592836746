<template>
  <div class="sidebar" :class="{ 'sidebar--open': isSidebarOpen }">
    <ul>
      <li
        v-for="(elem, idx) in docsData"
        :key="`${elem.slug}${idx}`"
        @click="selectItem"
      >
        <router-link
          class="sidebar__link"
          :class="{
            'sidebar__item--active': elem.slug === $route.params.slug
          }"
          :to="elem.slug"
        >
          {{ elem[`title_${locale}`] }}
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import { scrollTo } from "vue-scrollto";

import { mapGetters } from "vuex";

export default {
  name: "SideBarMenu",
  props: {
    isSidebarOpen: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      docsData: "app/getDocsData",
      locale: "app/getLocale"
    })
  },
  methods: {
    selectItem() {
      this.$emit("close-sidebar");
      scrollTo({
        container: "body",
        duration: 500,
        easing: "ease",
        offset: 0,
        force: true,
        cancelable: true,
        onStart: false,
        onDone: false,
        onCancel: false,
        x: false,
        y: true
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar {
  background-color: $main-text;
  position: fixed;
  z-index: 5;
  top: 60px;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  color: $main-text;
  width: 300px;
  bottom: 0;
  border-right: 1px solid $special-text;
  padding-top: 10px;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $special-text;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: darken($main-text, 10%);
  }
  @media (max-width: 1000px) {
    position: fixed;
    left: -350px;
    transition: left 0.3s ease;
    @media (max-width: 425px) {
      left: -100%;
      width: 100%;
      border: none;
    }
    &--open {
      left: 0;
    }
  }
  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 5px;
    padding: 10px 25px;
    border-radius: 10px;
    text-decoration: none;
    transition: color 0.3s ease;
    color: #1c1e21;
    font-weight: 500;
    gap: 10px;
    font-size: 16px;
    font-weight: bold;
    @media (max-width: 1800px) {
      padding: 10px 25px;
      font-size: 14px;
    }
    @media (max-width: 1024px) {
      font-size: 0.875rem;
    }
    @media (max-width: 1400px) {
      padding: 15px 20px;
      gap: 10px;
    }
    &:hover {
      color: $light-green;
      background-color: $special-text;
    }
    &--special {
      padding-left: 50px;
      font-size: 14px;
      font-weight: 400;
      @media (max-width: 1024px) {
        font-size: 12px;
      }
    }
  }

  &__icon {
    font-size: 26px;
  }
}
.router-link-exact-active {
  color: $light-green !important;
}
.green {
  color: $light-green;
}
</style>
