<template>
  <div class="anchors" v-show="anchorList.length">
    <ul class="anchors__list">
      <a
        v-for="(anchor, idx) in anchorList"
        :key="anchor.id + idx"
        @click="scrollToElement(anchor.id)"
        >📃 {{ anchor.text }}
      </a>
    </ul>
  </div>
</template>
<script>
export default {
  name: "AnchorsList",
  props: {
    anchorList: {
      require: true,
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  methods: {
    scrollToElement(id) {
      this.$emit("scroll-to", id);
    }
  }
};
</script>
<style lang="scss" scoped>
.anchors {
  position: fixed;
  top: 60px;
  right: 0;
  height: 100%;
  width: 250px;
  background-color: $main-text;
  color: $dark-color;
  border-left: 1px solid $special-text;
  padding: 15px 20px;
  transition: color 0.3s ease;

  @media (max-width: 1000px) {
    display: none;
  }
  @media (max-width: 1800px) {
    font-size: 14px;
  }
  @media (max-width: 1024px) {
    font-size: 0.875rem;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    > a {
      cursor: pointer;
    }
    > a:hover {
      color: $light-green;
    }
  }
}
.dark-theme {
  .anchors {
    background-color: #0a0a0b;
    color: #dadde1;
    border-left: 1px solid #606770;
  }
}
</style>
