<template>
  <div class="loading">Loading...</div>
</template>
<script>
export default {
  name: "LoadingComponent"
};
</script>
<style lang="scss" scoped>
.loading {
  margin-top: 60px;
  text-align: center;
  padding: 10vh;
  height: calc(100vh - 60px);
}
</style>
