export default {
  btn_in: "Log in",
  "footer-item-1": "Home",
  "footer-item-2": "About Us",
  "footer-item-3": "First Steps",
  "footer-item-4": "Investing",
  "footer-item-5": "Referral Program",
  "footer-item-6": "Statistics",
  "footer-item-7": "System Status",

  "footer-item-8": "Sections",
  "footer-item-8-1": "Resources",
  "footer-item-9": "Company",
  "footer-item-9-1": "AML Policy",
  "footer-item-9-2": "Website Terms of Use",
  "footer-item-9-3": "Privacy Policy",

  "footer-item-10": "Contact Us",
  "footer-item-11": "FAQ",
  "footer-item-12": "Documentation",

  "footer-form-1": "Name",
  "footer-form-2": "Phone",
  "footer-form-3": "Email",
  "footer-form-4": "Your Message",
  "footer-btn": "Send",
  "pop-up-title": "Your message has been successfully sent"
};
