<template>
  <div class="wrapper">
    <anchors-list
      v-if="anchorList.length"
      :anchorList="anchorList"
      @scroll-to="scrollToElement"
    />
    <h1 class="content-title"><span> 💡 </span> {{ content.title }}</h1>
    <div v-html="content.htmlContent" ref="contentRef" class="content"></div>
    <app-footer @open-popup="openPopup" />
    <popup-item v-if="popupOpen" @close-popup="closePopup" />
  </div>
</template>
<script>
import { scrollTo } from "vue-scrollto";

import { mapGetters } from "vuex";
import AppFooter from "../components/AppFooter.vue";
import AnchorsList from "./AnchorsList.vue";
import PopupItem from "../components/PopupItem.vue";
export default {
  name: "MainContent",
  components: { AnchorsList, AppFooter, PopupItem },
  data() {
    return {
      popupOpen: false,
      isDropdownOpen: false
    };
  },

  mounted() {
    // const currentPath = this.$route.path
    // const slug = this.docsData[0].slug
    // if (`/${this.locale}/${slug}` !== currentPath) {
    //   // console.log("currentPath", currentPath)
    //   console.log("${this.locale}/${slug}", `${this.locale}/${slug}`)
    //   console.log("currentPath - ", currentPath)
    //   console.log("slug - ", slug)
    //   this.$router.push({ path: `/${this.locale}/${slug}` })
    // }
  },
  computed: {
    ...mapGetters({
      locale: "app/getLocale",
      docsData: "app/getDocsData"
    }),
    content() {
      const pattern = /style="[^"]*color:\s*#[a-fA-F0-9]{6}[^"]*"/g;
      let htmlContent = this.docsData
        .find((elem) => elem.slug === this.$route.params.slug)
        [`text_${this.locale}`].replace(pattern, "")
        ?.replace(/<img[^>]*>/g, (match) => {
          return (
            match
              .replace(/(style="[^"]*)width:[^;]+;?/, "$1width: 80%;")
              .replace(/(style="[^"]*)height:[^;]+;?/, "$1height: 80%;") + '"'
          );
        });
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlContent;
      const tables = tempDiv.querySelectorAll("table");
      tables.forEach((table) => {
        const parentDiv = document.createElement("div");
        parentDiv.style.overflowX = "auto";
        table.parentNode.replaceChild(parentDiv, table);
        parentDiv.appendChild(table);
      });
      const blockquotes = tempDiv.querySelectorAll("blockquote");
      blockquotes.forEach((blockquote) => {
        blockquote.style.padding = "20px";
        blockquote.style.border = "1px solid #2e8555";
        blockquote.style.borderRadius = "8px";
        blockquote.style.marginTop = "8px";
        blockquote.style.marginBottom = "8px";
      });

      htmlContent = tempDiv.innerHTML;

      let title = this.docsData.find(
        (elem) => elem.slug === this.$route.params.slug
      )[`title_${this.locale}`];

      return { htmlContent: htmlContent, title: title };
    },
    anchorList() {
      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        this.content.htmlContent,
        "text/html"
      );
      const elementsWithIds = parsedHTML.querySelectorAll("[id]");

      const idList = [];
      elementsWithIds.forEach((element) => {
        idList.push({ id: element.id, text: element.textContent });
      });
      return idList;
    }
  },
  methods: {
    closePopup() {
      this.popupOpen = false;
    },
    openPopup() {
      this.popupOpen = true;
    },
    scrollToElement(id) {
      const element = this.$refs.contentRef.querySelector(`#${id}`);

      if (element) {
        scrollTo(element, {
          offset: -70,
          duration: 500
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.content-title {
  //   color: $main-text;
  display: flex;
  padding-top: 30px;
  font-size: 40px;
  font-weight: bold;
  color: #1c1e21;
  @media (max-width: 1800px) {
    font-size: 30px;
  }
  @media (max-width: 1450px) {
    font-size: 28px;
  }
}
.content {
  padding-top: 30px;
  padding-left: 15px;
}

.dark-theme {
  .content-title {
    color: #dadde1 !important;
  }
  .content span[style="color"] {
    color: #dadde1 !important;
  }
}
</style>
