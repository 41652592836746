import ApiClient from "@/libs/http-client";

const AppServices = {
  // eslint-disable-next-line no-unused-vars
  endpoints(route, params) {
    const url = {
      getDocsData: `documentation/`
    };

    return url[route];
  },

  get(url, data) {
    return ApiClient.get(this.endpoints(url, data));
  },
  post(url, data, params = {}, config = {}) {
    return ApiClient.post(this.endpoints(url, params), data, config);
  },
  patch(url, data, params = {}, config = {}) {
    return ApiClient.patch(this.endpoints(url, params), data, config);
  },
  delete(url, data, params = {}, config = {}) {
    return ApiClient.delete(this.endpoints(url, params), data, config);
  }
};

export default AppServices;
