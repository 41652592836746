import en from './messages/en'
import es from './messages/es'
import it from './messages/it'
import nl from './messages/nl'
import ru from './messages/ru'
import tr from './messages/tr'
import de from './messages/de'
import fa from './messages/fa'

import Vue from 'vue'
import store from '@/store'
import VueI18n from 'vue-i18n'

const getLocaleFromCookie = () => {
  const topLevelDomain = '.coinfuze.com'
  const cookieValue = document.cookie
    .split('; ')
    .find((row) => {
      const [key, value] = row.split('=')
      return key === 'locale' && value && value.endsWith(topLevelDomain)
    })
    ?.split('=')[1]
  return cookieValue ? decodeURIComponent(cookieValue) : null
}

const getStoreLocale = store.getters['app/getLocale']
const getBrowserLocale = () => {
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language

  if (!navigatorLocale) return undefined

  return navigatorLocale.trim().split('-').shift()
}

const defaultLanguage = getLocaleFromCookie()
  ? getLocaleFromCookie()
  : getStoreLocale
  ? getStoreLocale
  : ['de', 'en', 'es', 'it', 'nl', 'ru', 'fa', 'tr'].includes(
      getBrowserLocale()
    )
  ? getBrowserLocale()
  : 'en'
console.log('defaultLanguage', defaultLanguage)
store.dispatch('app/setLocale', defaultLanguage)

const messages = {
  de,
  en,
  es,
  it,
  nl,
  fa,
  ru,
  tr
}

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: defaultLanguage,
  fallbackLocale: 'en',
  messages: messages
})
export default i18n
